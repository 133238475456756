import Page from "../../components/page"
import SEO from "../../components/seo"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Challenge from "../../components/challenge"
import ReferencesSection from "../../components/content-sections/references-section"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  BuildingBlock,
  BuildingBlocksGrid,
} from "../../components/building-blocks"
import { ContactForm } from "../../components/contact-form"
import {
  ExpertDisplay,
  ExpertDisplayStack,
} from "../../components/expert-display"
import { graphql, useStaticQuery } from "gatsby"
import { OneByOneGrid } from "../../components/layout/grid"

const ValidatingPage = ({ location }: { location: Location }) => {
  const { formatMessage } = useIntl()
  const {
    evolvingImageData,
    christianMImageData,
    eugenImageData,
  } = useStaticQuery(
    graphql`
      query {
        evolvingImageData: file(
          relativePath: { eq: "challenges/evolving.png" }
        ) {
          ...DefaultImageOptions
        }
        christianMImageData: file(
          relativePath: { eq: "members/christian_m.jpg" }
        ) {
          ...DefaultImageOptions
        }
        eugenImageData: file(relativePath: { eq: "members/eugen.jpg" }) {
          ...DefaultImageOptions
        }
      }
    `
  )

  const evolvingImage = getImage(evolvingImageData)

  return (
    <Page>
      <SEO
        title={formatMessage({ id: `challenge.evolving.title` })}
        description={formatMessage({
          id: `challenge.evolving.shortDescription`,
        })}
        // ogImage={} TODO
        location={location}
      />

      <Challenge kind={"evolving"}>
        <OneByOneGrid>
          <p>
            {formatMessage({ id: `challenge.evolving.description.intro` })}
            <br />
            <br />
            {formatMessage({ id: `challenge.evolving.description.main1` })}{" "}
            <b>{formatMessage({ id: `challenges.weHelpMakeADifference` })}</b>{" "}
            {formatMessage({ id: `challenge.evolving.description.main2` })}
            <br />
            <br />
            {formatMessage({ id: `challenges.weHelpToEmbed` })}{" "}
            <b>{formatMessage({ id: `challenges.drivingBottomUpChange` })}</b>
          </p>
          <div>
            <GatsbyImage image={evolvingImage!} alt="" />
          </div>
        </OneByOneGrid>

        <h2>{formatMessage({ id: `challenges.howWeCanHelp` })}</h2>

        <BuildingBlocksGrid>
          <BuildingBlock kind={"evolving"} id={"frontend-optimization"} />

          <BuildingBlock kind={"evolving"} id={"ab-testing"} />

          <BuildingBlock kind={"evolving"} id={"cloud"} />

          <BuildingBlock kind={"evolving"} id={"containerization"} />

          <BuildingBlock kind={"evolving"} id={"cicd"} />

          <BuildingBlock kind={"evolving"} id={"architecture"} />
        </BuildingBlocksGrid>

        <h2>{formatMessage({ id: `challenges.references` })}</h2>
        <ReferencesSection
          ids={["microfrontends", "data-driven-experimentation"]}
        />

        <ContactForm
          title={formatMessage({ id: `challenges.getInTouch` })}
          initialMessage={"Hi Eugen, Christian \n\n"}
        >
          <ExpertDisplayStack>
            <ExpertDisplay
              name={"Eugen"}
              title={"Software Engineer"}
              description={"TODO: add short description"}
              imageData={eugenImageData}
            />
            <ExpertDisplay
              name={"Christian"}
              title={"Solution Architect"}
              description={"TODO: add short description"}
              imageData={christianMImageData}
            />
          </ExpertDisplayStack>
        </ContactForm>
      </Challenge>
    </Page>
  )
}

export default ValidatingPage
